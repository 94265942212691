import React from 'react';
import {Helmet} from 'react-helmet';
import '../../assets/css/location.css';
import useGoogleAnalyticsPageview from '../../hooks/useGoogleAnalyticsPageview';
import Layout from '../Shared/Layout';

const MAX_MAP_HEIGHT = 1110;
const MIN_MAP_HEIGHT = 700;

function LocationPageTemplate({city, mapSrc, addresses, locationImgSrc, analyticsPageviewTitle}) {
  useGoogleAnalyticsPageview({title: analyticsPageviewTitle});

  return (
    <Layout headerPosition="fixed">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{city} Office | Tintash</title>
      </Helmet>
      <div
        style={{
          '--map-height': !!mapSrc ? MAX_MAP_HEIGHT : MIN_MAP_HEIGHT,
        }}
        className="mapouter"
      >
        {!!mapSrc && (
          <div className="gmap_canvas">
            <iframe
              width="100%"
              id="gmap_canvas"
              src={mapSrc}
              frameBorder={0}
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
              // A hacky way to hide the address bar embedded by google maps
              style={{border: 0, marginTop: '-150px'}}
              height={MAX_MAP_HEIGHT + 300}
            ></iframe>
          </div>
        )}
        <div className="tintash-location__container">
          <div className="tintash-location__content">
            {Boolean(addresses?.length) &&
              addresses.map(({title, description}, i) => (
                <div key={title + i}>
                  <h1 className="tintash-location__name">{title}</h1>
                  <p className="tintash-location__address">{description}</p>
                </div>
              ))}
          </div>
          <div className="tintash-location__image">
            <img src={locationImgSrc} alt={city} />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default LocationPageTemplate;
