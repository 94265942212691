import React from 'react';
import LocationPageTemplate from '../../components/Location/LocationPageTemplate';

function IslamabadOffice() {
  return (
    <LocationPageTemplate
      analyticsPageviewTitle="Location/Pakistan/Islamabad"
      city="Islamabad"
      mapSrc="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13284.357455829768!2d73.0372389!3d33.6548463!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe4dcedbad65e0d3d!2sKNCT+Hub!5e0!3m2!1sen!2s!4v1563188926106!5m2!1sen!2s"
      addresses={[
        {
          title: 'Tintash Pvt Ltd',
          description: (
            <>
              KNCT Hub , <br />
              Plot 59G, Street 7, I-10/3 , <br />
              Islamabad Capital Territory, Pakistan
            </>
          ),
        },
      ]}
      locationImgSrc={require('../../assets/images/location/isl.png').default}
    />
  );
}

export default IslamabadOffice;
