import {useEffect} from 'react';
import ReactGA from 'react-ga';
import {useLocation} from '@reach/router';

const useGoogleAnalyticsPageview = ({title} = {}) => {
  const {pathname} = useLocation();
  useEffect(() => {
    ReactGA.set({
      title,
    });
    ReactGA.pageview(pathname);
  }, [title, pathname]);
};

export default useGoogleAnalyticsPageview;
